import React, { FormEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import {
  Button,
  ButtonType,
  Form,
  formatPhoneNumberIntl,
  FormFieldSize,
  FormGroup,
  Input,
  InputType,
  Select,
  SubmitButton
} from '@digicert/dcone-common-ui'
import { connect } from 'react-redux'
import { IAppState } from '../../../core/store/store'
import { Translate } from 'react-localize-redux'
import { editUser } from '../../../core/actions/Users.actions'
import { fetchProfile } from '../../../core/actions/Profile.actions'
import { initLocalization } from '../../../core/actions/Localization.actions'
import history from '../../../core/history/history'
import { IEditProfileProps } from './EditProfile.types'
import { IAccessScope } from '../../../reducers/Reducers.interfaces'
import Cookies from 'js-cookie'
import { DCONE_LOCALE_COOKIE_NAME } from '../../../core/utils/constants'
import { BasePathConst } from '../../../configs/Path.configs'
import { isValidEmail, translate } from '../../../shared/helpers/utils'
import { checkIfUsernameExists } from 'public-pages/self-service-user/SelfServiceUser.actions'
import styles from './EditProfile.module.scss'

const EditProfile = (
  {
    fetchProfile,
    checkIfUsernameExists,
    localeList,
    profile,
    editUser,
    initLocalization
  }: IEditProfileProps): ReactElement => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userName, setUserName] = useState('')
  const [samlUserName, setSamlUserName] = useState('')
  const [oidcUserName, setOidcUserName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [language, setLanguage] = useState('')
  const [nameDuplicateError, setNameDuplicateError] = useState(false)
  const [oneLoginUser, setOneLoginUser] = useState<boolean>(false)

  const changeFirstName = useCallback(
    (event) => setFirstName(event.target.value),
    [setFirstName]
  )

  const changeLastName = useCallback(
    (event) => setLastName(event.target.value),
    [setLastName]
  )

  const changeEmail = useCallback(
    (event) => setEmail(event.target.value),
    [setEmail]
  )
  
  const validateEmail = ():string => {
    if(!isValidEmail(email)){
      return translate('manageUser.administrators.validation.invalidEmail') as string
    }
    return ''
  }

  const validateUserName = (): any => {
    if (nameDuplicateError) {
        return translate('notifications.exceptions.USERNAME_EXISTS');
      }
  }

  const changeUserName = useCallback(
    (event) => {
      setUserName(event.target.value)
      checkIfUsernameExists(event.target.value, undefined, 'userName', profile!.id).then((res:boolean) => {
        if (res) {
            setNameDuplicateError(true)
        } else {
            setNameDuplicateError(false)
        }
    })
    },
    [setUserName]
  )

  const changeSamlUserName = useCallback(
    (event) => setSamlUserName(event.target.value),
    [setSamlUserName]
  )

  const changeOidcUserName = useCallback(
      (event) => setOidcUserName(event.target.value),
      [setOidcUserName]
  )

  const changePhoneNumber = useCallback(
    (value) => {
      if (value) {
        setPhoneNumber(value);
      }
    },
    [setPhoneNumber]
  )

  const changeLanguage = (value): void => {
    setLanguage(value)
  };

  const getLocaleOptions = () => {
    return localeList.locales.map((locale) => {
      return {
        label: locale.name,
        value: locale.id
      }
    })
  }

  const onCancel = (event) => {
    event.preventDefault()
    history.goBack()
  }

  const updateProfile = async (event:FormEvent): Promise<void> => {
    event.preventDefault()

    if(profile) {
      const profileData = {
        first_name: firstName,
        last_name: lastName,
        user_name: userName,
        email: email,
        phone: formatPhoneNumberIntl(phoneNumber),
        locale: language,
        permissions: profile.permissions,
        accounts: profile.access_scope === IAccessScope.account ? profile.accounts.filter(account => account.active) : [],
        access_scope: profile.access_scope,
      }

      await editUser(profile.id, profileData, false)
      Cookies.set(DCONE_LOCALE_COOKIE_NAME, profileData.locale)
      await initLocalization(profileData.locale).then(() => {
        window.location.href = `${BasePathConst}/profile`
      })
    }
  }

  useEffect(() => {
    fetchProfile().then((response: any) => {
      setFirstName(response.first_name)
      setLastName(response.last_name)
      setUserName(response.user_name)
      setEmail(response.email)
      // phone number maybe optional
      setPhoneNumber(response.phone || '')
      setLanguage(response.locale)
      setSamlUserName(response.saml_username)
      setOidcUserName(response.oidc_username)
      setOneLoginUser(response.has_onelogin_id)
    })
  }, [])

  return (
    <section className={styles.editProfile}>
      <h1>
        <Translate id='profile.title'/>
      </h1>

      <Form size={FormFieldSize.L} onSubmit={updateProfile}>
        <FormGroup>
          <Input
            label={<Translate id='common.form.firstName'/>}
            required
            value={firstName}
            errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
            onChange={changeFirstName}
            disabled={oneLoginUser}
          />

          <Input
            label={<Translate id='common.form.lastName'/>}
            required
            value={lastName}
            errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
            onChange={changeLastName}
            disabled={oneLoginUser}
          />
        </FormGroup>


        <FormGroup>
          <Input
            label={<Translate id='common.form.stdUsername'/>}
            required
            value={userName}
            errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
            onChange={changeUserName}
            validate={validateUserName}
            disabled={oneLoginUser}
          />
        </FormGroup>

        {samlUserName && (
          <FormGroup>
            <Input
              label={<Translate id='common.form.samlUsername'/>}
              required
              value={samlUserName}
              errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
              onChange={changeSamlUserName}
              disabled={oneLoginUser}
              readOnly
            />
          </FormGroup>
        )}

        {oidcUserName && (
            <FormGroup>
              <Input
                  label={<Translate id='common.form.oidcUsername'/>}
                  required
                  value={oidcUserName}
                  errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
                  onChange={changeOidcUserName}
                  disabled={oneLoginUser}
                  readOnly
              />
            </FormGroup>
        )}

        <FormGroup>
          <Input
            label={<Translate id='profile.email'/>}
            type={InputType.EMAIL}
            value={email}
            onChange={changeEmail}
            disabled={oneLoginUser}
            validate={validateEmail}
          />
        </FormGroup>

        <FormGroup>
          <Input
            label={<Translate id='profile.phone'/>}
            type={InputType.TELEPHONE}
            value={phoneNumber}
            errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
            onChange={changePhoneNumber}
            disabled={oneLoginUser}
          />
        </FormGroup>

        <FormGroup>
          <Select
            getPopupContainer={triggerNode => triggerNode.parentElement}
            label={<Translate id='profile.language'/>}
            options={getLocaleOptions()}
            defaultValue={language}
            disabled={oneLoginUser}
            value={language}
            errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
            onChange={changeLanguage}
          />
        </FormGroup>

        <div className={styles.formActions}>
          <Button buttonType={ButtonType.SECONDARY} onClick={onCancel} id='cancel_btn_profile'>
            <Translate id='profile.cancelBtn'/>
          </Button>
          {!oneLoginUser &&
            <SubmitButton id='save_or_update_contact_btn_profile'>
              <Translate id='profile.updateBtn' />
            </SubmitButton>
          }
          
        </div>
      </Form>
    </section>
  )
}

export default connect(
  (state: IAppState) => ({
    profile: state.profile.data,
    isEditing: state.users.isEditing,
    localeList: state.locales
  }),
  { editUser, fetchProfile, initLocalization, checkIfUsernameExists }
)(EditProfile)