import React from 'react'
import { CustomCard, Tooltip } from '@digicert/dcone-common-ui'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencil} from '@fortawesome/pro-light-svg-icons'
import { translate } from '../../../../shared/helpers/utils'
import { Translate } from 'react-localize-redux'
import styles from './LicenseCard.module.scss'
import {EditButtonRoleTypes, LicenseCardProps} from './LicenseCard.types'
import cn from 'classnames'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const LicenseCard = (
  {
    data,
    canEdit,
    id,
    onEditClick,
    title,
    editButtonRole
  }: LicenseCardProps) => {

  return (
    <CustomCard>
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <div className={styles.label}>{title}</div>
            <br/>
            <div>
           { title === 'Device Trust Manager' &&
                  <h4 className={styles.subTitle}>
                  <a href='https://docs.digicert.com/en/device-trust-manager/licensing-and-plans.html' className={styles.learnMore} target='_blank' rel='noopener noreferrer'>
                     <Translate id='dashboardLicense.devicePlansLeanMore'/>
                  </a>
                  </h4>
            }
            </div>

          <div className={styles.action}>
            <button
              aria-label={translate('common.edit') as string + ' ' + title}
              role={editButtonRole ? editButtonRole : EditButtonRoleTypes.BUTTON}
              onClick={() => {onEditClick(id, data)}}
              className={cn(
                {
                  [styles.cantEditLicenseBtn]: !canEdit,
                  [styles.editLicenseBtn]: canEdit,
                }
              )}
            >
              <Tooltip placement='top' title={translate('profile.editBtn') + ' ' + title}>
                <FontAwesomeIcon size='lg' icon={faPencil as IconProp}/>
              </Tooltip>
            </button>
          </div>
        </div>
        <div className={styles.cardContentRowHeader}>
          <p><Translate id='common.form.licenseType'/></p>
          <p><Translate id='common.form.Remaining'/></p>
          <p><Translate id='common.form.totalAllocated'/></p>
        </div>
        {data.map((item, index) => {
          return (
            <div key={index} className={styles.cardContentRow}>
              <p>{item.licenseType}</p>
              <p>{item.remaining}</p>
              <p>{item.totalAllocated}</p>
            </div>
          )
        })}
      </div>
    </CustomCard>
  )
}